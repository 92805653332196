@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&family=Poppins:wght@600&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@layer base {
  body {
    @apply bg-grey-3 font-primary text-base leading-relaxed;
  }
  .section {
    @apply py-[50px] lg:py-[70px];
  }
  .title {
    @apply text-2xl lg:text-[40px] leading-tight font-semibold mb-4;
  }
  .subtitle {
    @apply text-[18px] lg:text-[20px] leading-relaxed mb-5 lg:mb-9;
  }
}
/* <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet">
<script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script> */

.back {
  background-image: url(./assets/img/99.png);
  background-position: center;
  background-size: cover;
}

.door {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 400px; 
  background-image: url("./assets/img/1500 x 400px.jpg");
  background-position: center;
  background-size: cover;
 border:"10px solid red";

}

.clay {
  background-image: url('./assets/img/1500 x 400px Terracotta.jpg');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  width: 100%; 
  height: 400px; 
}


@media only screen and (min-width: 1200px) {
  .clay {
    height: 400px; 
  }
}


@media only screen and (max-width: 1024px) {
  .clay {
    height: 200px; 
  }
}


@media only screen and (max-width: 768px) {
  .clay {
    height: 250px;
  }
}


@media only screen and (max-width: 480px) {
  .clay {
    height: 104px; 
}

}

.wood {
  background-image: url('./assets/img/wooden.webp');
  background-size: auto;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
}



.shadow-2 {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 20px 10px -20px;
}

.test_bg{
  background-image: url('./assets/img/test_bg.jpg');
  background-size: cover;
  background-position: top; 
  /* margin-top: -100px; */
}

.bg-new {
  background-image: url('./assets/img/email-bg.jpg');
  background-size: cover;
  background-position: top;
}

.img:hover {
  scale: 1.04;
  transition: all ease-in .3s;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 15px 15px;

}

.slide-in{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background:#0f0f0f;
  transform-origin: bottom;
}
.slide-out{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background:#0f0f0f;
  transform-origin: top;
}


.title {
  font-family: 'Poppins', sans-serif;
  color: #212121;
  font-size:18px;
}

.text {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  color: #707070;
}



@keyframes rotate360 {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.rotate-animation {
  animation: rotate360 2s linear infinite;
}